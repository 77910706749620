import "./App.css";
import Header from "./Header/Header";
import IntroSection from "./Components/IntroSection/IntroSection";
import CVSection from "./Components/Introduction/Introduction";
import ServicesSection from "./Components/ServicesSection/ServicesSection";
import HIW from "./Components/HIW/HIW";
import ContactSection from "./Components/Contact/Contact";
import React, { useEffect, useState } from "react";
import { LangProvider } from "./Contexts/LangContext";
import Price from "./Components/Price/PriceSection";
import Impress from "./Components/Impress/Impress";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ServiceElement from "./Components/ServiceElement/ServiceElement";
import { useTranslation } from "react-i18next";

function App() {
  const [location, setLocation] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (location && window.location.hash) {
        const y = document
          .getElementById(
            window.location.hash.slice(1, window.location.hash.length)
          )
          .getBoundingClientRect().y;
        window.scrollTo({
          top: y,
          left: 0,
          behavior: "smooth",
        });
      }
    }, 100);
  }, [location]);

  const { t } = useTranslation();

  return (
    <div>
      <LangProvider value="hun">
        <Router>
          <Header location={location} setLocation={setLocation} />
          <Switch>
            <Route exact path="/">
              <IntroSection />
              <CVSection />
              <ServicesSection setLocation={setLocation} />
              <HIW />
              <Price />
              <ContactSection />
              <Impress />
            </Route>
            <Route exact path="/modern">
              <ServiceElement
                props={{
                  title: t("ModernKitchen"),
                  text: t("ModernDesc"),
                  pic1: "Images/modernX1 (6).jpg",
                  pic2: "Images/modernX2 (3).jpg",
                  pic3: "Images/modernX1 (4).jpg",
                  pic4: "Images/modernX1 (1).jpg",
                  setLocation,
                }}
              />
            </Route>
            <Route exact path="/rustic">
              <ServiceElement
                props={{
                  title: t("RusticKitchen"),
                  text: t("RusticDesc"),
                  pic1: "Images/rusticX2 (6).jpg",
                  pic2: "Images/rusticX2 (2).jpg",
                  pic3: "Images/rusticX1 (5).jpg",
                  pic4: "Images/rustic_outer.jpg",
                  setLocation,
                }}
              />
            </Route>
            <Route exact path="/luxury">
              <ServiceElement
                props={{
                  title: t("LuxuryKitchen"),
                  text: t("LuxyDesc"),
                  pic1: "Images/luxiX3 (1).jpg",
                  pic2: "Images/luxiMain.jpg",
                  pic3: "Images/luxiX1 (1).jpg",
                  pic4: "Images/luxiX2 (4).jpg",
                  setLocation,
                }}
              />
            </Route>
            {/*<Route exact path="/minimal">
              <ServiceElement
                props={{
                  title: "some title",
                  text: "some text",
                  pic1: "http://cataas.com/c",
                  pic2: "http://cataas.com/c",
                  pic3: "http://cataas.com/c",
                  pic4: "http://cataas.com/c",
                }}
              />
              </Route>*/}
            <Route exact path="/3D">
              <ServiceElement
                props={{
                  title: t("3D"),
                  text: t("3DDesc"),
                  pic1: "Images/3D2.jpeg",
                  pic2: "Images/3DX1 (3).png",
                  pic3: "Images/3DX1 (5).png",
                  pic4: "Images/3DX1 (6).png",
                  setLocation,
                }}
              />
            </Route>
            <Redirect to="/" />
          </Switch>
        </Router>
      </LangProvider>
    </div>
  );
}

export default App;
