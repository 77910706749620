import "./Contact.css";
import { AiOutlineFacebook } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const ContactDetails = () => {
  const { t } = useTranslation();

  return (
    <Fade left>
      <div id="ContactDetails">
        <div id="ContactTitle">{t("ContactTitle")}</div>
        <div class="ContactText">
          <span class="ContactSubTitle" style={{ fontSize: "30px" }}>
            {t("Company_Name")}
          </span>
        </div>
        <div class="ContactText">
          <span class="ContactSubTitle">{t("AddressTitle")}</span>
          {t("Address")}
        </div>
        <div class="ContactText">
          <span class="ContactSubTitle">Telefon: </span>
          <a href="tel:+3630/096-5590">+3630/096-5590</a>
        </div>
        <div class="ContactText">
          <span class="ContactSubTitle">{t("EmailTitle")}</span>
          <a href="mailto:kranitz.butoralkotas@gmail.com">
            kranitz.butoralkotas@gmail.com
          </a>
        </div>
        <div class="ContactText">
          <a
            href="https://www.facebook.com/profile.php?id=100091519234200"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineFacebook size={50} />
          </a>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2697.6457330452854!2d16.6776658!3d47.457844800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476ea72b04083711%3A0xe54615dee8604f25!2sZsira%2C%20R%C3%A1k%C3%B3czi%20Ferenc%20u.%2011%2C%209476!5e0!3m2!1shu!2shu!4v1668351886880!5m2!1shu!2shu"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </Fade>
  );
};

export default ContactDetails;
