import "./PriceSection.css";
import { useLang } from "../../Contexts/LangContext";
import { Fade } from "react-reveal";

const Price = () => {
  const [lang] = useLang(useLang);

  return (
    <div id="Price">
      <Fade>
        {lang === "hu" ? (
          <div id="PriceTitle">Fizetési feltételek</div>
        ) : (
          <div id="PriceTitle">Zahlungsbedingungen</div>
        )}
        <div>
          {lang === "hu" ? (
            <div id="PriceText">
              <ol>
                <li>
                  Az elfogadott árajánlat alapján a megrendeléskor 40 % előleget
                  kérünk el.
                </li>
                <li>
                  A szállítás előtt szintén 40 % - át kérjük el az árnak.{" "}
                </li>
                <li>Beépítés után pedig a maradék 20 % kerül kifizetésre.</li>
              </ol>
            </div>
          ) : (
            <div id="PriceText">
              <ol>
                <li>
                  Aufgrund des angenommenen Kostenvoranschlags wird zum
                  Zeitpunkt der Bestellung eine Anzahlung von 40 % des
                  Gesamtpreises fällig.
                </li>
                <li>
                  Außerdem bitten wir Sie um eine weitere Zahlung von 40% des
                  Preises vor Lieferung.
                </li>
                <li>
                  Nach Installation ist der Restbetrag von 20% zu Begleichen.
                </li>
              </ol>
            </div>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default Price;
