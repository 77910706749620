import "./Introduction.css";
import { useTranslation } from "react-i18next";
import { useLang } from "../../Contexts/LangContext";
import { Fade } from "react-reveal";

const CVSection = () => {
  const { t } = useTranslation();

  const [lang] = useLang();

  return (
    <div id="Introduction">
      <div class="CVHeader">{t("Introduction")}</div>
      <Fade>
        {lang === "hu" ? (
          <div id="IntroductionContentWrapper">
            <div id="IntroductionContent">
              Cégünk családi vállalkozásként jött létre Bútoralkotás Kft. néven,
              amely asztalosipari munkákkal foglalkozik, elsősorban egyedi
              konyhabútorok megalkotásával. Munkáink során elsődleges
              szempontnak tartjuk, hogy minőségi faalapanyagok felhasználásával
              egyedi megjelenésű kifogástalan asztalos munkákat valósítsunk meg.
              <br />
              <br />
              Megbízható szaktanácsadás, személyes ügyfélkezelés és rugalmas
              kiszolgálás várja és segíti Önt döntéseiben. Forduljon hozzánk
              bizalommal álmai konyhabútorának megtervezésével és
              kivitelezésével.
              <p align="right">
                <i>Kránitz György és Kránitz Mátyás</i>
              </p>
            </div>
            <img id="red_dress" src="Images/image0.jpeg" alt="red_dress_img" />
          </div>
        ) : (
          <div id="IntroductionContentWrapper">
            <div id="IntroductionContent">
              Unsere Firma wurde als Familienunternehmen unter dem Namen
              „Kranitz Möbelbau Kft.” gegründet.
              <br />
              <br />
              Wir übernehmen Tischlerarbeiten und stellen vor allem individuelle
              Küchen her. Bei unserer Arbeit legen wir großen Wert auf die
              Verwendung hochwertiger Rohstoffe, um tadellose Möbel mit
              einzigartiger Optik zu Erschaffen.
              <br />
              <br />
              Wir bieten Ihnen zuverlässige Fachberatung, persönliche
              Kundenbetreuung mit einem flexiblen Service und helfen Ihnen bei
              Ihrer Entscheidung. Wenden Sie sich vertrauensvoll an uns, um Ihre
              Traumküche zu Planen und Auszuführen.
              <p align="right">
                <i>György Kránitz und Mátyás Kránitz</i>
              </p>
            </div>
            <img id="red_dress" src="Images/image0.jpeg" alt="red_dress_img" />
          </div>
        )}
      </Fade>
    </div>
  );
};

export default CVSection;

/*

*/
