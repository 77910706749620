import "./IntroSection.css";
import { useLang } from "../../Contexts/LangContext";
import { useTranslation } from "react-i18next";

const IntroSection = () => {
  const { t } = useTranslation();

  const [lang] = useLang();

  return (
    <div class="IntroWrapper">
      <div class="Intro">
        <b>{t("Company_Name")}</b>
        {lang === "hu" ? (
          <div id="citation">
            <b>,,A minőség magáért beszél"</b>
          </div>
        ) : (
          <div id="citation">,,Die Qualität spricht für sich"</div>
        )}
      </div>
    </div>
  );

  //blur on he bottom of pic
};

export default IntroSection;
