import "./ServicesSection.css";
import Fade from "react-reveal";
import { useTranslation } from "react-i18next";
import ServiceCard from "./ServiceCard";
import Grid from "@mui/material/Grid";

const ServicesSection = (props) => {
  const { t } = useTranslation();

  return (
    <div id="areasOfExpertise">
      <div className="CVHeader">{t("Services")}</div>
      <Fade>
        <Grid container spacing={2}>
          <Grid item xs={window.innerWidth > 600 ? 6 : 12}>
            <ServiceCard
              img="Images/modernX1 (8).jpg"
              title={t("ModernKitchen")}
              text={t("ModernShortDesc")}
              link="/modern"
              setLocation={props.setLocation}
            />
          </Grid>
          <Grid item xs={window.innerWidth > 600 ? 6 : 12}>
            <ServiceCard
              img="Images/rusticMain.jpg"
              title={t("RusticKitchen")}
              text={t("RusticShort")}
              link="/rustic"
              setLocation={props.setLocation}
            />
          </Grid>
          <Grid item xs={window.innerWidth > 600 ? 6 : 12}>
            <ServiceCard
              img="Images/luxiX1 (2).jpg"
              title={t("LuxuryKitchen")}
              text={t("LuxyShortDesc")}
              link="/luxury"
              setLocation={props.setLocation}
            />
          </Grid>
          <Grid item xs={window.innerWidth > 600 ? 6 : 12}>
            <ServiceCard
              img="Images/3D2.jpeg"
              title={t("3D")}
              text={t("3DShort")}
              link="/3D"
              setLocation={props.setLocation}
            />
          </Grid>
          {/*<Grid item xs={window.innerWidth > 600 ? 6 : 12}>
            <ServiceCard
              img="http://cataas.com/c"
              title={t("MinimalKitchen")}
              text="some text about minimal kitchens"
              link="/minimal"
            />
          </Grid>
          <Grid item xs={12}>
            <ServiceCard
              img="http://cataas.com/c"
              title={t("3D")}
              text="some text about 3D-Design"
              link="/3D"
            />
  </Grid>*/}
        </Grid>
      </Fade>
    </div>
  );
};

export default ServicesSection;
