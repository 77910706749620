const MenuItems = [
  //all i18n
  {
    title: "Rólunk",
    titledeu: "Über uns",
    url: "#Introduction",
    cName: "nav-links",
  },
  {
    title: "Szolgáltatásaink",
    titledeu: "Unsere Dienstleistungen",
    url: "#areasOfExpertise",
    cName: "nav-links",
  },
  {
    title: "Hogyan dolgozunk?",
    titledeu: "Wie arbeiten wir?",
    url: "#HIW",
    cName: "nav-links",
  },
  {
    title: "Fizetési feltételek",
    titledeu: "Zahlungsbedingungen",
    url: "#Price",
    cName: "nav-links",
  },
  {
    title: "Kapcsolat",
    titledeu: "Kontakt",
    url: "#Contact",
    cName: "nav-links",
  },
  {
    title: "Hun",
    cName: "lang-btn",
  },
  {
    title: "Ger",
    cName: "lang-btn",
  },
];

export { MenuItems };
