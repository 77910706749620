import React, { useEffect } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import Impress from "../Impress/Impress";
import "./ServiceElement.css";

const ServiceElement = (props) => {
  const itemData = [
    props.props.pic1,
    props.props.pic2,
    props.props.pic3,
    props.props.pic4,
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Fade>
      <div
        className="bg"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <p
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "35px",
            fontFamily: "system-ui",
          }}
        >
          {/*<Link to="/">
          <FaArrowCircleLeft
            onClick={() => {
              props.props.setLocation(true);
            }}
            color={"red"}
            size={40}
          />
          </Link>*/}

          <b>{props.props.title}</b>
        </p>
        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: props.props.text }}
        />
        <div className="imgContainer">
          {itemData.map((item, index) => (
            <img
              src={item}
              style={{
                width: "100%",
                borderRadius: "10px",
                maxHeight: "50vh",
                border: "1px solid grey",
              }}
              alt="A kitchen"
              key={index}
            />
          ))}
        </div>
      </div>
      <Impress />
    </Fade>
  );
};

export default ServiceElement;
