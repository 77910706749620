import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ServiceCard = (props) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ width: "88%", marginLeft: "6%" }}>
      <CardMedia component="img" height="200px" image={props.img} />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          sx={{ fontWeight: "bold" }}
          component="div"
        >
          {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.text}
        </Typography>
      </CardContent>
      <CardActions>
        <Link to={props.link}>
          <Button
            size="small"
            onClick={() => {
              props.setLocation(false);
            }}
          >
            {t("More")}
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default ServiceCard;
