const WorkingSteps = [
  {
    title: "Kapcsolat felvétel",
    titleGer: "Kontakt:",
    description:
      'Vegye fel velünk a kapcsolatot és keressenek minket bizalommal telefonon: <a href="tel:+3630/096-5590">+3630/096-5590</a>  vagy emailen: <a href="mailto:kranitz.butoralkotas@gmail.com">kranitz.butoralkotas@gmail.com</a>. ',
    descriptionGer:
      'Wir freuen uns über Ihre Kontaktaufnahme: <a href="tel:+3630/096-5590">+3630/096-5590</a> / e-Mail: <a href="mailto:kranitz.butoralkotas@gmail.com">kranitz.butoralkotas@gmail.com</a>.',
  },
  {
    title: "Egyeztetés",
    titleGer: "Vereinbarung",
    description:
      "Igényeinek megfelelően egyeztetés a konyhabútor kialakításával kapcsolatban. Szükség esetén helyszíni felmérés. ",
    descriptionGer:
      "Beratung zur Möbelgestaltung, Ihren Wünschen entsprechend. Begutachtung ggf. vor Ort.",
  },
  {
    title: "Látványterv és árajánlat",
    titleGer: "Ansichtsplan und Preisangebot",
    description:
      "Előzetes egyeztetés alapján elkészítjük Önnek a megálmodott konyhabútor látványtervét és árajánlatát. ",
    descriptionGer:
      "Nach vorheriger Konsultation erstellen wir Ihnen den Kostenvoranschlag für Ihre Traumküche.",
  },
  {
    title: "Méretek felvétele",
    titleGer: "Dimensionen hinzufügen",
    description:
      "Az árajánlat elfogadása esetén felvesszük a végleges méreteket a helyszínen.",
    descriptionGer:
      "Wird das Preisangebot akzeptiert, nehmen wir die endgültigen Maße vor Ort.",
  },
  {
    title: "Munkafolyamat megkezdése",
    titleGer: "Beginn des Arbeitsprozesses",
    description:
      "A munkafolyamatok teljes lefolyása alatt folyamatos tájékoztatást kínálunk, hogy a végeredmény a lehető legtökéletesebb legyen. ",
    descriptionGer:
      "Während des gesamten Arbeitsablaufes bieten wir kontinuierlich Auskunft, damit das Endergebnis so perfekt wie möglich wird.",
  },
  {
    title: "Szállítás és beszerelés",
    titleGer: "Lieferung und Installation",
    description: "Az elkészült konyhabútor kiszállítása és beszerelése",
    descriptionGer: "Lieferung und Montage der fertigen Küchenmöbel",
  },
];

export { WorkingSteps };
